var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-3"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-dialog',{attrs:{"max-width":"1100px","persistent":"","transition":"dialog-top-transition"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close.apply(null, arguments)}},model:{value:(_vm.showDetail),callback:function ($$v) {_vm.showDetail=$$v},expression:"showDetail"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))]),_c('v-spacer'),_c('v-radio-group',{attrs:{"row":"","mandatory":"","dense":""},model:{value:(_vm.service),callback:function ($$v) {_vm.service=$$v},expression:"service"}},[_c('v-radio',{attrs:{"label":"Produits","value":0}}),_c('v-radio',{attrs:{"label":"Service","value":1}})],1)],1),_c('v-card-text',[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[(_vm.cmd.source_id == '1')?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"8"}},[_c('v-autocomplete',{attrs:{"item-value":'id',"items":_vm.products_list,"filter":_vm.customFilter,"label":"Produit","rules":[function (v) { return !!v || 'Produit obligatoire'; }],"dense":"","loading":_vm.loading},on:{"change":_vm.produit_change},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.no_da + "-" + (item.code ? item.code + "-" : "") + item.label)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.no_da + "-" + (item.code ? item.code + "-" : "") + item.label + "-" + item.qte_reste + " " + item.unit_name)+" ")]}}],null,false,289540165),model:{value:(_vm.editedItem.dem_det_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "dem_det_id", $$v)},expression:"editedItem.dem_det_id"}})],1):_vm._e(),(_vm.cmd.source_id == '0' && _vm.allproduct)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"10"}},[_c('cursorselect',{key:_vm.cs,attrs:{"Qsearch":_vm.products_cursor,"Qresp":'products_cursor',"value":_vm.editedItem.produit_id,"text_fields":['code', 'label'],"variableadd":_vm.variableadd,"whereadd":_vm.whereadd,"label":'Produit'},on:{"change":_vm.produit_change}})],1):_vm._e(),(_vm.cmd.source_id == '0' && !_vm.allproduct)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"10"}},[_c('v-autocomplete',{attrs:{"item-value":_vm.allproduct || (_vm.tiertype == 1 && _vm.spec && !_vm.allproduct)
                      ? 'id'
                      : 'produit_id',"items":_vm.products_list,"filter":_vm.customFilter,"label":"Produit","rules":[
                    function (v) { return !!v || 'Produit obligatoire'; },
                    function (v) { return _vm.cnv_ok || 'Produit introuvable dans la convention'; } ],"dense":"","loading":_vm.loading},on:{"change":_vm.produit_change},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s((_vm.allproduct || (_vm.tiertype == 1 && _vm.spec && !_vm.allproduct) ? item.code : item.product_code) + "-" + (item.ref ? "(" + item.ref + ")" + "-" : "") + (_vm.allproduct || (_vm.tiertype == 1 && _vm.spec && !_vm.allproduct) ? item.label : item.product_name))+" ")]}},{key:"item",fn:function(ref){
                  var item = ref.item;
return [_c('v-list-item-content',[_vm._v(" "+_vm._s((_vm.allproduct || (_vm.tiertype == 1 && _vm.spec && !_vm.allproduct) ? item.code : item.product_code) + "-" + (_vm.allproduct || (_vm.tiertype == 1 && _vm.spec && !_vm.allproduct) ? item.label : item.product_name))+" "),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.ref ? "Ref. Ext. " + item.ref : "")+" ")])],1)]}}],null,false,2880943643),model:{value:(_vm.editedItem.produit_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "produit_id", $$v)},expression:"editedItem.produit_id"}})],1):_vm._e(),(
                  _vm.spec && (_vm.tiertype == 1 || _vm.tiertype == 3 || _vm.tiertype == 5)
                )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"1"}},[_c('v-checkbox',{attrs:{"label":"Tous","dense":""},model:{value:(_vm.allproduct),callback:function ($$v) {_vm.allproduct=$$v},expression:"allproduct"}})],1):_vm._e(),(_vm.categorie_list.length > 1 && _vm.tiertype <= 4)?_c('v-col',{attrs:{"cols":"12","sm":"1","md":"2"}},[_c('v-select',{ref:"categories",attrs:{"items":_vm.categorie_list,"item-text":"categorie","item-value":"categorie_id","label":"Categorie","readonly":_vm.readonly,"rules":[function (v) { return !!v || 'Categorie obligatoire'; }],"dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.color,"small":""}},[_c('span',[_vm._v(_vm._s(item.categorie))])])]}},{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.color,"small":""}},[_c('span',[_vm._v(_vm._s(item.categorie))])])]}}],null,false,2947607996),model:{value:(_vm.editedItem.categorie_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "categorie_id", $$v)},expression:"editedItem.categorie_id"}})],1):_vm._e(),(
                  _vm.editedItem.produit_id && (_vm.tiertype == 2 || _vm.tiertype == 4)
                )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_c('v-autocomplete',{attrs:{"item-value":'id',"items":_vm.articles,"filter":_vm.customFilter,"label":"Article","dense":"","clearable":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s((item.code ? item.code + "-" : "") + item.article_name)+" ")]}},{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(
                          (item.code ? item.code + '-' : '') +
                          item.article_name
                        )}})],1)]}}],null,false,2032670279),model:{value:(_vm.editedItem.article_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "article_id", $$v)},expression:"editedItem.article_id"}})],1):_vm._e()],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-text-field',{ref:"pu",staticClass:"inputPrice",attrs:{"autocomplete":"off","type":"number","dense":"","readonly":!_vm.edit_price,"label":'Prix ' + (_vm.cmd.devise ? '(' + _vm.cmd.devise + ')' : ''),"rules":[
                    function (v) { return !!v || _vm.tiertype == 2 || 'Prix obligatoire'; },
                    function (v) { return !v || _vm.tiertype == 2 || v > 0 || 'Valeur incorrecte'; } ],"hide-spin-buttons":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$refs.qte.focus()},"focus":function($event){return $event.target.select()}},model:{value:(_vm.editedItem.pu),callback:function ($$v) {_vm.$set(_vm.editedItem, "pu", _vm._n($$v))},expression:"editedItem.pu"}})],1),(_vm.tiertype <= 4)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-text-field',{ref:"qte",staticClass:"inputPrice",attrs:{"autocomplete":"off","type":"number","dense":"","readonly":_vm.readonly,"label":'Quantité',"rules":[
                    function (v) { return !!v || 'Quantité obligatoire'; },
                    function (v) { return !v || v > 0 || 'Valeur incorrecte'; } ],"disabled":_vm.save_disable,"hide-spin-buttons":"","hint":_vm.editedItem.fk_unit != _vm.editedItem.fk_unit_product &&
                    _vm.editedItem.qte2
                      ? _vm.editedItem.qte2 + ' ' + _vm.editedItem.unit
                      : ''},on:{"input":_vm.qte_change,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.save.apply(null, arguments)},"focus":function($event){return $event.target.select()}},model:{value:(_vm.editedItem.qte),callback:function ($$v) {_vm.$set(_vm.editedItem, "qte", $$v)},expression:"editedItem.qte"}})],1):_vm._e(),(_vm.tiertype <= 4)?_c('v-col',{attrs:{"cols":"12","sm":"1","md":"2"}},[_c('v-select',{ref:"unite",attrs:{"items":_vm.units_list,"item-text":"name","item-value":"id","label":"Unité de Mesure","readonly":_vm.readonly,"rules":[function (v) { return !!v || 'Unité de Mesure obligatoire'; }],"dense":""},model:{value:(_vm.editedItem.fk_unit),callback:function ($$v) {_vm.$set(_vm.editedItem, "fk_unit", $$v)},expression:"editedItem.fk_unit"}})],1):_vm._e(),(_vm.cmd.currency_id == 114)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"1"}},[_c('v-text-field',{ref:"tva",staticClass:"inputPrice",attrs:{"autocomplete":"off","type":"number","dense":"","label":"TVA","readonly":_vm.tiertype == 1 || _vm.tiertype == 3 || _vm.tiertype == 5
                      ? _vm.readonly ||
                        !(
                          _vm.$store.state.auth.includes('02027') ||
                          _vm.$store.state.isadmin
                        )
                      : _vm.readonly,"rules":[function (v) { return !v || v > 0 || 'Valeur incorrecte'; }],"disabled":_vm.cmd.exe_tva,"hide-spin-buttons":""},on:{"focus":function($event){return $event.target.select()}},model:{value:(_vm.editedItem.tva_tx),callback:function ($$v) {_vm.$set(_vm.editedItem, "tva_tx", _vm._n($$v))},expression:"editedItem.tva_tx"}})],1):_vm._e(),(_vm.tiertype <= 4)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-text-field',{ref:"delai",staticClass:"inputPrice",attrs:{"autocomplete":"off","type":"number","dense":"","label":"Delai (jours)","rules":[
                    function (v) { return !v ||
                      (v > 0 &&
                        (_vm.cmd.delai
                          ? _vm.verif_date(_vm.cmd.date_cmd, v, _vm.cmd.date_limite)
                          : true)) ||
                      'Date Max ' + _vm.datefr(_vm.cmd.date_limite); } ],"hide-spin-buttons":"","hint":_vm.cmd.date_limite
                      ? 'Delai Liv. : ' + _vm.datefr(_vm.cmd.date_limite)
                      : '',"readonly":_vm.readonly},on:{"input":_vm.addDays,"focus":function($event){return $event.target.select()}},model:{value:(_vm.editedItem.delai),callback:function ($$v) {_vm.$set(_vm.editedItem, "delai", _vm._n($$v))},expression:"editedItem.delai"}}),_vm._v(" "+_vm._s(_vm.duedate ? "Avant le : " + _vm.duedate : "")+" ")],1):_vm._e(),(_vm.tiertype <= 4)?_c('v-col',{attrs:{"cols":"12","sm":"1","md":"3"}},[(_vm.tiertype == 1 || _vm.tiertype == 3)?_c('v-select',{ref:"adresse",attrs:{"items":_vm.adresses,"item-text":function (item) { return item.label +
                      '-' +
                      item.departement_name +
                      '-' +
                      item.ville_name; },"item-value":"id","label":"Adresse de livraison","readonly":_vm.readonly,"dense":""},model:{value:(_vm.editedItem.adress_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "adress_id", $$v)},expression:"editedItem.adress_id"}}):_vm._e()],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"1","md":"12"}},[_c('v-text-field',{ref:"description",attrs:{"autocomplete":"off","dense":"","label":"Description Additionnelle"},on:{"focus":function($event){return $event.target.select()}},model:{value:(_vm.editedItem.description),callback:function ($$v) {_vm.$set(_vm.editedItem, "description", $$v)},expression:"editedItem.description"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"1","md":"10"}},[_c('v-text-field',{ref:"comment",attrs:{"autocomplete":"off","dense":"","label":"Commentaire","readonly":_vm.readonly},on:{"focus":function($event){return $event.target.select()}},model:{value:(_vm.editedItem.comment),callback:function ($$v) {_vm.$set(_vm.editedItem, "comment", $$v)},expression:"editedItem.comment"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-container',{attrs:{"fill-height":""}},[(_vm.progress)?_c('v-layout',{attrs:{"row":"","justify-center":"","align-center":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":30,"width":7,"color":"purple"}})],1):_vm._e()],1),_c('v-spacer'),(!_vm.readonly)?_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":_vm.save_disable},on:{"click":_vm.save}},[_vm._v(" Enregistrer ")]):_vm._e(),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.close.apply(null, arguments)}}},[_vm._v(" Fermer ")])],1)],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":_vm.snackbar_timeout,"top":"","color":_vm.snackbar_color},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }